import { useState, useContext } from "react";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
const Login = () => {
  const [teamName, setTeamName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ teamName: "" });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const signIn = async () => {
    if (!teamName || !password) {
      return;
    }
    setLoading(true);
    try {
      await signInWithEmailAndPassword(
        auth,
        `${teamName.replace(/ /g, "")}@mvet.hackathon`,
        password
      );
      navigate("/");
    } catch (e) {
      if (e.code === "auth/invalid-credential") {
        setErrors({
          teamName: "Either team name does not exist or password is incorrect",
        });
      } else {
        console.log(e);
        setLoading(false);
        alert(e.message);
      }
    }
    setLoading(false);
  };
  return user ? (
    <Navigate to="/" />
  ) : (
    <div className="d-flex flex-column">
      <div className="fs-1 text-center" style={{ marginBottom: "75px" }}>
        M-Vet Hackathon 2024
      </div>
      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          <div className="d-flex flex-column">
            <div className="fs-3 text-warning text-center mb-3">Login</div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Team Name"
                value={teamName}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    teamName: "",
                  });
                  setTeamName(e.target.value);
                }}
              />
              <div
                className="text-danger text-start"
                style={{ fontSize: "12px" }}
              >
                {errors.teamName}
              </div>
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    teamName: "",
                  });
                  setPassword(e.target.value);
                }}
              />
            </div>

            <button
              className="btn btn-warning text-white"
              type="button"
              onClick={signIn}
            >
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Sign In"
              )}
            </button>
            <div className="mt-2">
              Don't have account?{" "}
              <Link to="/register" className="text-warning">
                Register
              </Link>
            </div>
          </div>
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
};

export default Login;
