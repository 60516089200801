import "./App.css";
import Register from "./components/register";
import Login from "./components/login";
import Navigator from "./components/navigator";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from "./components/protected";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route path="/register" exact element={<Register />} />
          <Route
            path="/"
            element={
              <Protected path="/login">
                <Navigator />
              </Protected>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
