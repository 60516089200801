import moment from "moment";
const Submissions = ({ submissions }) => {
  return (
    <div>
      <div className="row text-muted fs-5 mb-3">
        <div className="col-2">ID</div>
        <div className="col-3">Submitted</div>
        <div className="col-4">Filename</div>
        <div className="col-3">Score</div>
      </div>
      {submissions.map((submission, index) => (
        <div key={submission.id}>
          <div className="row fs-5">
            <div className="col-2">{index + 1}</div>
            <div className="col-3">
              {moment(submission.submitted).fromNow()}
            </div>
            <div className="col-4">{submission.filename}</div>
            <div className="col-3">{submission.score}</div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default Submissions;
