import { useState, useContext, useEffect } from "react";
import NavBar from "./navbar";
import Team from "./team";
import { AuthContext } from "../contexts/authContext";
import { UserContext } from "../contexts/userContext";
import Submissions from "./submission";
import Leaderboard from "./leaderboard";

const Navigator = () => {
  const [currentChallengeIndex, setCurrentChallengeIndex] = useState(0);
  const [active, setActive] = useState(0);
  const [currentChallengeSubmissions, setCurrentChallengeSubmissions] =
    useState([]);
  const [currentChallengeBestSubmissions, setCurrentChallengeBestSubmissions] =
    useState([]);
  const { signOut } = useContext(AuthContext);
  const { submissions, leaderboard } = useContext(UserContext);

  const challenges = [
    {
      name: "Body score prediction",
      image: "/pexels-photo-422218.jpeg",
    },
    // {
    //   name: "Well identification and detection",
    //   image: "/istockphoto-1368887558-612x612.jpg",
    // },
    {
      name: "Animal type detection",
      image: "/pexels-photo-2722455.jpeg",
    },
  ];

  useEffect(() => {
    const filtered = submissions.filter(
      (submission) =>
        submission.challenge === challenges[currentChallengeIndex].name
    );
    setCurrentChallengeSubmissions(filtered);
  }, [submissions, currentChallengeIndex]);

  useEffect(() => {
    if (leaderboard.length > 0) {
      const currentChallenge = challenges[currentChallengeIndex].name
        .toLowerCase()
        .split(" ")
        .join("_");
      const currentChallengeBestSubmissionDetails = leaderboard.map(
        (teamDetails) => {
          var data = {
            lastSubmission: teamDetails["lastSubmission"]
              ? teamDetails["lastSubmission"][currentChallenge]
              : undefined,
            maxScore: teamDetails["maxScores"]
              ? teamDetails["maxScores"][currentChallenge]
              : undefined,
            teamName: teamDetails["teamName"],
            submissionsCount: teamDetails["submissionsCount"]
              ? teamDetails["submissionsCount"][currentChallenge]
              : undefined,
          };
          return data;
        }
      );

      const hasNullScore = currentChallengeBestSubmissionDetails.filter(
        (detail) => detail.maxScore === undefined
      );
      const hasScore = currentChallengeBestSubmissionDetails.filter(
        (detail) => detail.maxScore !== undefined
      );

      hasScore.sort((a, b) => b.maxScore - a.maxScore);
      const sorted_details = [...hasScore, ...hasNullScore];
      setCurrentChallengeBestSubmissions(sorted_details);
    }
  }, [leaderboard, currentChallengeIndex]);

  return (
    <div className="d-flex flex-column mx-3 mt-2">
      <div className="d-flex justify-content-end mt-2">
        <div className="dropdown">
          <button
            className="btn btn-warning text-white fs-5 dropdown-toggle px-3"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Switch Challenge
          </button>
          <ul className="dropdown-menu">
            {challenges.map((challenge, index) => (
              <li key={index}>
                <button
                  type="button"
                  className="dropdown-item btn"
                  onClick={() => {
                    setCurrentChallengeIndex(index);
                    setActive(0);
                  }}
                >
                  {challenge.name}
                </button>
              </li>
            ))}
            <li key={3} className="px-3 mt-2">
              <button
                type="button"
                className="btn btn-danger dropdown-item text-center rounded-2 py-1 bg-warning text-white"
                onClick={() => signOut()}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="position-relative">
        <img
          className="rounded-3 img-fluid"
          src={challenges[currentChallengeIndex].image}
          alt="Challenge Image"
          style={{ maxHeight: "300px", objectFit: "cover", width: "100%" }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-white">
          <p style={{ fontSize: "50px" }}>
            {challenges[currentChallengeIndex].name} challenge
          </p>
        </div>
      </div>
      <div className="ms-3 mt-3">
        <div className="mb-3">
          <NavBar
            active={active}
            setActive={setActive}
            challenge={challenges[currentChallengeIndex].name}
          />
        </div>
        <div className="ms-2">{active == 1 && <Team />}</div>
        <div className="ms-2">
          {active == 2 && (
            <Submissions submissions={currentChallengeSubmissions} />
          )}
        </div>
        <div className="ms-2">
          {active == 0 && (
            <Leaderboard maxSubmissions={currentChallengeBestSubmissions} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigator;
