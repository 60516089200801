import { UserContext } from "../contexts/userContext";
import { useContext } from "react";
const Team = () => {
  const { teamData } = useContext(UserContext);
  return (
    teamData != null && (
      <div className="d-flex">
        <div className="d-flex flex-column me-3">
          <span className="fw-semibold fs-5">Team Name</span>
          <span>{teamData.teamName}</span>
        </div>
        <div className="d-flex flex-column">
          <span className="fw-semibold fs-5">Team Members</span>
          {teamData.teamMembers.map((member, index) => (
            <span key={index}>{member}</span>
          ))}
        </div>
      </div>
    )
  );
};

export default Team;
