import { useState, useContext } from "react";
import { db, auth } from "../firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
const Register = () => {
  const [teamName, setTeamName] = useState("");
  const [password, setPassword] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [errors, setErrors] = useState({
    teamName: "",
    password: "",
    teamMembers: "",
  });
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const teamNameExists = async (teamName) => {
    const q = query(collection(db, "Teams"), where("teamName", "==", teamName));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
      return true;
    } else {
      return false;
    }
  };

  const signUp = async () => {
    try {
      if (errors.teamName || errors.password) {
        return;
      }
      if (teamMembers.length < 4) {
        setErrors({
          ...errors,
          teamMembers: "Team should have atleast 4 members",
        });
        return;
      }
      if (teamMembers.length > 5) {
        setErrors({
          ...errors,
          teamMembers: "Team should have atmost 5 members",
        });
        return;
      }
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        `${teamName.replace(/ /g, "")}@mvet.hackathon`,
        password
      );

      const uid = userCredential.user.uid;

      await setDoc(doc(db, "Teams", uid), {
        teamName: teamName,
        teamMembers: teamMembers,
      });
      navigate("/");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      alert(e.message);
    }
  };
  return user ? (
    <Navigate to="/" />
  ) : (
    <div className="d-flex flex-column">
      <div className="fs-1 text-center" style={{ marginBottom: "75px" }}>
        M-Vet Hackathon 2024
      </div>
      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          <div className="d-flex flex-column">
            <div className="fs-3 text-warning text-center mb-3">Register</div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Team Name"
                value={teamName}
                onBlur={async () => {
                  const teamExists = await teamNameExists(teamName);
                  if (teamExists) {
                    setErrors({
                      ...errors,
                      teamName: "Team Name already exists",
                    });
                  }
                }}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    teamName: "",
                  });
                  setTeamName(e.target.value);
                }}
              />
              <div
                className="text-danger text-start"
                style={{ fontSize: "12px" }}
              >
                {errors.teamName}
              </div>
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Enter Password"
                value={password}
                onBlur={() => {
                  if (password.length < 6) {
                    setErrors({
                      ...errors,
                      password: "Password should have atleast 6 characters",
                    });
                  }
                }}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    password: "",
                  });
                  setPassword(e.target.value);
                }}
              />
              <div
                className="text-danger text-start"
                style={{ fontSize: "12px" }}
              >
                {errors.password}
              </div>
            </div>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Team member"
                  value={teamMember}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      teamMembers: "",
                    });
                    setTeamMember(e.target.value);
                  }}
                />
                <button
                  className="btn btn-warning text-white"
                  type="button"
                  onClick={() => {
                    if (teamMember) {
                      setTeamMembers([...teamMembers, teamMember]);
                      setTeamMember("");
                    }
                  }}
                >
                  Add member
                </button>
              </div>
              <div className="d-flex column-gap-2 row-gap-2 flex-wrap mt-1">
                {teamMembers.map((member, index) => (
                  <div
                    key={index}
                    className="bg-light text-dark px-3 py-2 rounded-2"
                  >
                    {member}
                  </div>
                ))}
              </div>
              <div
                className="text-danger text-start"
                style={{ fontSize: "12px" }}
              >
                {errors.teamMembers}
              </div>
            </div>
            <button
              className="btn btn-warning text-white"
              type="button"
              onClick={signUp}
            >
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Sign Up"
              )}
            </button>
            <div className="mt-2">
              Already have account?{" "}
              <Link to="/login" className="text-warning">
                Login
              </Link>
            </div>
          </div>
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
};

export default Register;
