import moment from "moment";
const Leaderboard = ({ maxSubmissions }) => {
  return (
    <div>
      <div className="row text-muted fs-5 mb-3">
        <div className="col-1">Rank</div>
        <div className="col-3">Team Name</div>
        <div className="col-2">Score</div>
        <div className="col-3">Last submission</div>
        <div className="col-1">#Submission</div>
      </div>
      {maxSubmissions.map((submission, index) => (
        <div key={index}>
          <div className="row fs-5">
            <div className="col-1">{index + 1}</div>
            <div className="col-3">{submission.teamName}</div>
            <div className="col-2">{submission.maxScore}</div>
            <div className="col-3">
              {submission.lastSubmission
                ? moment(submission.lastSubmission).fromNow()
                : null}
            </div>
            <div className="col-1">{submission.submissionsCount}</div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default Leaderboard;
