import { useState, useContext, useRef } from "react";
import { AuthContext } from "../contexts/authContext";
import Papa from "papaparse";
const Score = ({ challenge }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const closeRef = useRef(null);
  const handleSubmit = async () => {
    if (!file) {
      return;
    }

    setLoading(true);

    Papa.parse(file, {
      header: true,
      complete: function (results) {
        fetch("https://score-submission-44nt5qsp3a-uc.a.run.app", {
          method: "POST",
          body: JSON.stringify({
            file: results.data.slice(0, -1),
            challenge: challenge,
            submitted: new Date(),
            user: user.uid,
            filename: file.name,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (!response.ok) {
              const data = await response.json();
              throw new Error(data.error);
            }
            return response.json();
          })
          .then((data) => {
            console.log(data);
            closeRef.current.click();
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error:", error);
            alert(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id="submission"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="d-flex justify-content-end mt-2 me-2">
            <button
              ref={closeRef}
              type="button"
              className="btn-close border-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setFile(null)}
            ></button>
          </div>
          <div className="m-5 d-flex flex-column">
            <div className="fs-3 text-start fw-semibold">Submission</div>
            <div
              className="d-flex justify-content-center rounded-3  py-5 mt-5"
              style={{ border: "1px dashed gray" }}
            >
              {file ? (
                <span>{file.name}</span>
              ) : (
                <div>
                  <label htmlFor="files" className="btn text-warning">
                    Choose submission file
                  </label>
                  <input
                    id="files"
                    style={{ visibility: "hidden" }}
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-items-start">
              {" "}
              <button
                disabled={loading}
                type="button"
                className="btn btn-warning text-white px-3 fs-5 mt-3"
                onClick={handleSubmit}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-white mx-5"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Get Score"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Score;
