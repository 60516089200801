import { useState, useEffect, createContext, useContext } from "react";
import { db, auth } from "../firebaseConfig";
import {
  getDoc,
  doc,
  collection,
  onSnapshot,
  orderBy,
  query,
  getDocs,
} from "firebase/firestore";
import { AuthContext } from "./authContext";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [teamData, setTeamData] = useState(null);
  const { user } = useContext(AuthContext);
  const [submissions, setSubmissions] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);

  useEffect(() => {
    if (user) {
      getDoc(doc(db, "Teams", auth.currentUser.uid)).then((docSnap) =>
        setTeamData(docSnap.data())
      );
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "Teams", user.uid, "Submissions"),
          orderBy("submitted", "desc")
        ),
        async (snapshot) => {
          const submissionsData = await Promise.all(
            snapshot.docs.map(async (doc) => {
              return { id: doc.id, ...doc.data() };
            })
          );
          setSubmissions(submissionsData);
        },
        (error) => {
          console.error("Error fetching submissions: ", error);
        }
      );

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(
        collection(db, "Teams"),

        async (snapshot) => {
          const teams = await Promise.all(
            snapshot.docs.map(async (doc) => {
              var submissionsSnap = await getDocs(
                collection(db, "Teams", doc.id, "Submissions")
              );
              var bodyScoreSubmissions = [];
              var animalTypeSubmissions = [];
              for (var snap of submissionsSnap.docs) {
                var data = snap.data();
                const deadlineDate = new Date("2024-08-26T23:59:59.999Z");
                const submissionDate = new Date(data["submitted"]);
                if (
                  data["challenge"] === "Body score prediction" &&
                  submissionDate <= deadlineDate
                ) {
                  bodyScoreSubmissions.push(data);
                } else if (
                  data["challenge"] === "Animal type detection" &&
                  submissionDate <= deadlineDate
                ) {
                  animalTypeSubmissions.push(data);
                }
              }
              const bodyScoreSubmission = bodyScoreSubmissions.reduce(
                (prev, current) =>
                  prev["score"] > current["score"] ? prev : current,
                {}
              );
              const animalTypeSubmission = animalTypeSubmissions.reduce(
                (prev, current) =>
                  prev["score"] > current["score"] ? prev : current,
                {}
              );

              const maxScores = {
                body_score_prediction: bodyScoreSubmission["score"],
                animal_type_detection: animalTypeSubmission["score"],
              };

              const lastSubmission = {
                body_score_prediction: bodyScoreSubmissions.reduce(
                  (prev, current) =>
                    new Date(prev["submitted"]) > new Date(current["submitted"])
                      ? prev
                      : current,
                  {}
                )["submitted"],
                animal_type_detection: animalTypeSubmissions.reduce(
                  (prev, current) =>
                    new Date(prev["submitted"]) > new Date(current["submitted"])
                      ? prev
                      : current,
                  {}
                )["submitted"],
              };

              const submissionsCount = {
                body_score_prediction: bodyScoreSubmissions.length,
                animal_type_detection: animalTypeSubmissions.length,
              };

              return {
                id: doc.id,
                teamName: doc.data()["teamName"],
                maxScores,
                lastSubmission,
                submissionsCount,
              };
            })
          );
          setLeaderboard(teams);
        },
        (error) => {
          console.error("Error fetching teams: ", error);
        }
      );

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ teamData, submissions, leaderboard }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
