import Score from "./score";

const NavBar = ({ challenge, setActive, active }) => {
  return (
    <div className="d-flex align-items-center">
      <button
        type="button"
        className={`btn border-0 fw-semibold fs-5 me-5 ${
          active == 0 && "text-warning"
        } `}
        onClick={() => setActive(0)}
      >
        Leaderboard
      </button>
      <button
        type="button"
        className={`btn border-0 fw-semibold fs-5 me-5 ${
          active == 1 && "text-warning"
        }`}
        onClick={() => setActive(1)}
      >
        Team
      </button>
      <button
        type="button"
        className={`btn border-0 fw-semibold fs-5 me-auto ${
          active == 2 && "text-warning"
        }`}
        onClick={() => setActive(2)}
      >
        Submissions
      </button>
      <div>
        <button
          type="button"
          className="btn btn-warning text-white px-3 fs-5"
          data-bs-toggle="modal"
          data-bs-target="#submission"
        >
          Get Score
        </button>
        <Score challenge={challenge} />
      </div>
    </div>
  );
};

export default NavBar;
